var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('ozi-table',{attrs:{"headers":_vm.table.headers,"items":_vm.table.items,"persistent":"languageCapabilityListTable"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.handleCreate.apply(null, arguments)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$tc('label.create'))+" ")],1)],1)]},proxy:true},{key:"item.domain",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.$tc(("languageCapability.domain." + (item.domain))))+" ")])]}},{key:"item.language",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.locales.find(function (locale) { return locale.value === item.language; }).label)+" ")])]}},{key:"item.visible",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mx-1",attrs:{"small":"","label":"","color":item.visible ? 'green' : 'red',"text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$tc(("languageCapability.visible." + (item.visible))))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleEdit(item)}}},[_vm._v("mdi-pencil")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }